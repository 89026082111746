<template>
  <div id="page-roles-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('role.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          @click="createDialog = true"
        >
          {{ $t('actions.createResource', { resource: $tc('role.title', 1) }) }}
        </v-btn>
        <RoleForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('role.title',1)"
          :values="createFormValues"
          :permission-fetcher="permissionsRequest"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <KCrudTable
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="role.fields"
          resource="role"
          @click:edit="openUpdate"
        />
      </template>
    </KCrudLayout>
    <RoleForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('role.title',1)"
      :values="updateFormValues"
      :permission-fetcher="permissionsRequest"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import Role from '@/application/models/Role.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import {
  createForEmployer as create,
  indexForEmployer as index,
  permissionsForEmployer as permissions,
  show,
  update,
} from '@/modules/permissions/api/role.js';
import RoleForm from '@/modules/permissions/components/RoleForm.vue';

export default {
  name: 'RoleIndex',
  components: {
    KCrudTable,
    RoleForm,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new Role(),
      createFormValues: new Role(),
    };
  },
  computed: {
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'settings.employer-roles' },
        text: this.$tc('role.title', 2),
      },
    ]);
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    createRequest() {
      return create(this.createFormValues);
    },
    indexRequest() {
      return index(...arguments);
    },
    permissionsRequest() {
      return permissions();
    },
    async openUpdate(item) {
      this.updateFormValues = new Role();
      const roleShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(roleShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
